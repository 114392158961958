<template>
  <div class="container">
    <div class="auth_login_form">
      <div class="log-card mb-4">
        <div class="dash_card_body ">
          <form @submit.prevent="resetPassword">
            <div v-if="errors ">
              <div class="alert alert-danger">
                <ul>
                  <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>Email:</label>
              <div>
                <input type="text" disabled  v-model="email"  @blur="v$.email.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.email.$error">
                <span v-if="v$.email.required.$invalid">Email is required</span>
                <span v-if="v$.email.email.$invalid">Email is invalid</span>
              </div>
            </div>
            <div class="form-group">
              <label>Password:</label>
              <div>
                <input type="password"  v-model="password"  @blur="v$.password.$touch" class="form-control" />
              </div>
              <div class="input_error" v-if="v$.password.$error">
                <span v-if="v$.password.required.$invalid">Password is required</span>
              </div>
            </div>
            <div class="form-group">
              <label>Confirm Password:</label>
              <div>
                <input type="password"  v-model="confirmPassword"  class="form-control" />
              </div>
              <div class="input_error" v-if="confirmPasswordError">
                <span>Password should match</span>
              </div>
            </div>
            <div class="d-flex justify--center mt-3">
              <button type="submit" class="btn-large btn-primary my-0"> <span><span v-if="loading" v-html="spinner"></span>  Reset Password </span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import { required,email } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: 'recover-password',
  setup() {
      return { v$: useVuelidate() }
  },
  data () {
    return {
      loading:false,
      email: '',
      password: '',
      errors: false,
      confirmPassword: '',
      confirmPasswordError: false,
      token: this.$route.params.token,
      spinner: "<i class='fa fa-spinner fa-spin '></i>"
    }
  },
  validations() {
        return {
            email: { required ,email},
            password: {required}
        }
  },
  mounted(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get('email')
      this.email = email;
  },
  watch: {
      confirmPassword(){
          if(this.password !== this.confirmPassword){
              this.confirmPasswordError = true;
          }else{
              this.confirmPasswordError = false;
          }
      }

  },
  
  methods: {
   async resetPassword(){
      this.loading = true;
      this.errors = false;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect && this.confirmPasswordError) {
          this.loading = false;
          return
      }
      

      const formData = new FormData();
      formData.append('token', this.token);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('password_confirmation',this.confirmPassword);
      const url = this.dashBaseUrl + "/auth/reset-password";
      this.axios.post(url, formData,).then((response) => {
              if(response.status == 200){
                this.errors= false;
                this.loading = false;
                toast.success('password reset was success');
                this.$router.push({
                        name: 'login'
                });
              }
              
          })
          .catch((error) => {
              this.loading = false;
              if(error.response.status === 400){
                this.errors = true;
                this.errorMsg = error.response.data.errors;
              }
          });
    }
  },
   mixins: [ReusableDataMixins]
}
</script>

<style lang="scss">
</style>
